$(document).ready(function() {
    //route select
    $('.route_select_box').click(function () {
        //check all selected
        var ids = [];
        $('.route_select_box:checked').each(function() {
            ids.push($(this).val());
        });
        //set nbr
        $('#route_count').html(ids.length);
        $('.form_route_ids').val("["+ids.join(',')+"]");
    });

    //color picker
    $('.color-picker').colorpicker();
    //screen
    $('#type_s-field').change(function () {
        var value = $("#type_s-field :selected").val();
        if(value != 0) {
            //is NOT route
            $("#screen_a").removeClass('hidden').addClass('show');
            $("#screen_c").removeClass('show').addClass('hidden');
        } else {
            // is route
            $("#screen_a").removeClass('show').addClass('hidden');
            $("#screen_c").removeClass('hidden').addClass('show');
        }
        if(value == 3) {
            //is HTML
            $("#screen_d").removeClass('show').addClass('hidden');
            $("#screen_b").removeClass('hidden').addClass('show');
        } else {
            $("#screen_d").removeClass('hidden').addClass('show');
            $("#screen_b").removeClass('show').addClass('hidden');
        }
    });

// partner: touchable
    $('td.touchable').click(function () {
        var current = $(this);
        var inputElem = current.children('.input_touch')[0];
        if(inputElem.value == 0) {
            //switch
            current.removeClass('red-bg');
            current.addClass('navy-bg');
            inputElem.value = 1;
        } else {
            current.removeClass('navy-bg');
            current.addClass('red-bg');
            inputElem.value = 0;
        }
    })
//
//DATA table
    $('.dataTables').DataTable({
        "processing": true,
        "language": {
            "url": "/js/plugins/dataTables/lang/"+current_locale+".json"
        },
        "order": [[ 0, "asc" ]],
        "lengthMenu": [50, 100],
        stateSave: true
    });

    var largeDataTable = $('.dataTables-largeData').DataTable({
        dom: 'Blfrtip',
        buttons: [
            'csvHtml5',
            {
                extend: 'print',
                customize: function ( win ) {
                    var style = $('<style>.print_color { display: block; }</style>')
                    $(win.document.head).append(style);
                }
            }

        ],
        "scrollX": true,
        "processing": true,
        "lengthMenu": [50, 100],
        "language": {
            "url": "/js/plugins/dataTables/lang/"+current_locale+".json"
        },
        "order": [[ 0, "asc" ]],
        stateSave: true
    });

    largeDataTable.on( 'draw', function () {
        setTimeout(function() {
            $('.sparklines_pie').each(function () {
                var innerContent = $(this).html().substring(1, 4);
                if(innerContent != "can") {
                    $(this).sparkline('html', {
                        type: 'pie',
                        sliceColors: ['#ED7100', '#B3B3B3', '#028A97'],
                        width: '25px',
                        height: '25px',
                        offset: -90
                    });
                }
            });
            $('.sparklines_pie_4').each(function () {
                var innerContent = $(this).html().substring(1, 4);
                if(innerContent != "can") {
                    $(this).sparkline('html', {
                        type: 'pie',
                        sliceColors: ['#ED7100', '#F8B000', '#3FD630', '#058B29'],
                        width: '25px',
                        height: '25px',
                        offset: -90
                    });
                }
            });
        }, 300);
    } );

// form
// form
    // remove "has-error" after add text (not full test)
    $("div.required").change(function () {
        if ($(this).find('input').length > 0) {
            if ($(this).find('input').first().val().length > 0) {
                $(this).removeClass('has-error');
            } else {
                $(this).addClass('has-error');
            }
        }
    });

    // remove "has-warning" after add text (not full test)
    $("div.has-warning").change(function () {
        if ($(this).find('input').first().val().length > 0) {
            $(this).removeClass('has-warning');
        }
    });

// multiselect
    $(".multi-select").chosen();

// chose selector
// chose selector

    //select all
    /*
    $('#select_all_holds>button').click(function(){
        $('#select_all_holds>option').prop('selected', true);
        $('#select_all_holds>.multi-select').trigger('liszt:updated');
    });*/

    $(".chosen-select").chosen();

    //uploader file
    $('#upload_path').change(function () {
        var file = this.files[0];
        if(file.size > (1048576 * 6)) { //upper upload limit for whole site: 6 MB
            swal({
                title: app_lng[current_locale].e_oops,
                text: app_lng[current_locale].e_upload_7,
                allowOutsideClick: true,
                type: "error"
            });
            return false;
        }
        sendFileToServer(file, '0', function (i) {
            $('#path').val(i);
        }, function (e) {
            var error_string = 'e_upload_' + e.toString();
            swal({
                title: app_lng[current_locale].e_oops,
                text: app_lng[current_locale][error_string],
                allowOutsideClick: true,
                type: "error"
            });
        }, 'file_upload', 'file_upload_progress');
    });

    //uploader cases
    $('#upload_image').change(function () {
        var file = this.files[0];
        if(file.size > (1048576 * 4)) { //upper upload limit for whole site: 4 MB
            swal({
                title: app_lng[current_locale].e_oops,
                text: app_lng[current_locale].e_upload_7,
                allowOutsideClick: true,
                type: "error"
            });
            return false;
        }
        var imagefile = file.type;
        var match = ["image/jpeg", "image/png", "image/jpg"];
        if (!((imagefile == match[0]) || (imagefile == match[1]) || (imagefile == match[2]))) {
            swal({
                title: app_lng[current_locale].e_upload_i,
                text: app_lng[current_locale].e_upload_3,
                allowOutsideClick: true,
                type: "error"
            });
            $('progress#file_upload').hide();
            $('#file_upload_progress').hide();
            return false;
        } else {
            sendFileToServer(file, '0', function (i) {
                    $('#image').val(i);
                    $('#image').trigger("change");
                }, function (e) {
                    var error_string = 'e_upload_' + e.toString();
                    swal({
                        title: app_lng[current_locale].e_oops,
                        text: app_lng[current_locale][error_string],
                        allowOutsideClick: true,
                        type: "error"
                    });
                }, 'file_upload', 'file_upload_progress');
        }
    });

    $('.upload_image_x').change(function () {
        var id = $(this).attr('data-upload_id');
        var file = this.files[0];
        if(file.size > (1048576 * 4)) { //upper upload limit for whole site: 4 MB
            swal({
                title: app_lng[current_locale].e_oops,
                text: app_lng[current_locale].e_upload_7,
                allowOutsideClick: true,
                type: "error"
            });
            return false;
        }
        var imagefile = file.type;
        var match = ["image/jpeg", "image/png", "image/jpg"];
        if (!((imagefile == match[0]) || (imagefile == match[1]) || (imagefile == match[2]))) {
            swal({
                title: app_lng[current_locale].e_upload_i,
                text: app_lng[current_locale].e_upload_3,
                allowOutsideClick: true,
                type: "error"
            });
            $('progress#file_upload_'+id).hide();
            $('#file_upload_progress_'+id).hide();
            return false;
        } else {
            sendFileToServer(file, '0', function (i) {
                $('#image_'+id).val(i);
                $('#image_'+id).trigger("change");
            }, function (e) {
                var error_string = 'e_upload_' + e.toString();
                swal({
                    title: app_lng[current_locale].e_oops,
                    text: app_lng[current_locale][error_string],
                    allowOutsideClick: true,
                    type: "error"
                });
            }, 'file_upload_'+id, 'file_upload_progress_'+id);
        }
    });

    //uploader cases
    $('#upload_image_gym').change(function () {
        var file = this.files[0];
        if(file.size > (1048576 * 6)) { //upper upload limit for whole site: 6 MB
            swal({
                title: app_lng[current_locale].e_oops,
                text: app_lng[current_locale].e_upload_7,
                allowOutsideClick: true,
                type: "error"
            });
            return false;
        }
        var imagefile = file.type;
        var match = ["image/jpeg", "image/png", "image/jpg"];
        if (!((imagefile == match[0]) || (imagefile == match[1]) || (imagefile == match[2]))) {
            swal({
                title: app_lng[current_locale].e_upload_i,
                text: app_lng[current_locale].e_upload_3,
                allowOutsideClick: true,
                type: "error"
            });
            $('progress#file_upload_gym').hide();
            $('#file_upload_progress_gym').hide();
            return false;
        } else {
            sendFileToServer(file, '0', function (i) {
                $('#gym_plan_path').val(i);
            }, function (e) {
                var error_string = 'e_upload_' + e.toString();
                swal({
                    title: app_lng[current_locale].e_oops,
                    text: app_lng[current_locale][error_string],
                    allowOutsideClick: true,
                    type: "error"
                });
            }, 'file_upload_gym', 'file_upload_progress_gym', $('#gym_id_hidden').val()); //'1' for is gym path
        }


    });

    $('#upload_profile').change(function () {
        var file = this.files[0];
        if(file.size > (1048576 * 4)) { //upper upload limit: 4 MB
            swal({
                title: app_lng[current_locale].e_oops,
                text: app_lng[current_locale].e_upload_7,
                allowOutsideClick: true,
                type: "error"
            });
            return false;
        }
        var imagefile = file.type;
        var match = ["image/jpeg", "image/png", "image/jpg"];
        if (!((imagefile == match[0]) || (imagefile == match[1]) || (imagefile == match[2]))) {
            swal({
                title: app_lng[current_locale].e_upload_i,
                text: app_lng[current_locale].e_upload_3,
                allowOutsideClick: true,
                type: "error"
            });
            $('progress#file_upload').hide();
            $('#file_upload_progress').hide();
            return false;
        } else {
            sendFileToServer(file, $('#user_id_hidden').val(), function (i) {
                $('#image').val(i);
            }, function (e) {
                var error_string = 'e_upload_' + e.toString();
                swal({
                    title: app_lng[current_locale].e_oops,
                    text: app_lng[current_locale][error_string],
                    allowOutsideClick: true,
                    type: "error"
                });
            }, 'file_upload', 'file_upload_progress');
        }


    });

    $('.summernote').summernote({
        toolbar: [
            // [groupName, [list of button]]
            ['basic', ['bold', 'italic', 'underline', 'strikethrough', 'clear']],
            ['font', ['fontname', 'fontsize', 'color']],
            ['para', ['ul', 'ol', 'paragraph']],
            ['insert', ['picture', 'link', 'video', 'table']],
            ['misc', ['undo', 'redo', 'codeview', 'fullscreen']]
        ],
        placeholder: app_lng[current_locale].placeholder,
        disableDragAndDrop: true,
        callbacks: {
            onImageUpload: function (files) {
                $('.summernote').before('<progress id="summernote_progress"></progress>');
                sendFile(files[0], $(this));
            }
        }
    });

    $('.summernote-simple').summernote({
        toolbar: [
            // [groupName, [list of button]]
            ['basic', ['bold', 'italic', 'underline', 'strikethrough']],
            ['font', ['fontsize', 'color']],
            ['para', ['ul', 'ol']],
            ['insert', ['picture', 'link']]
        ],
        placeholder: app_lng[current_locale].placeholder,
        disableDragAndDrop: true,
        callbacks: {
            onImageUpload: function (files) {
                $('.summernote-simple').before('<progress id="summernote_progress"></progress>');
                sendFile(files[0], $(this));
            }
        }
    });

    //DATES

    $(".date-birthday").datepicker({
        format: 'd M yyyy',
        endDate: '-48m',
        startDate: '-100y',
        autoclose: true,
        todayBtn: true,
        todayHighlight: true,
        language: current_locale,
    }).on('hide', function (e) {
        if(e.date) {
            var dateString = e.date.getFullYear().toString() + '-' + two_days_digit(e.date.getMonth() + 1) + '-' + two_days_digit(e.date.getDate());
            $('.real-date-birthday').attr('value', dateString);
        }
    }).each(function() {
        if ($(this).val() != '0000-00-00') {
            var d = new Date($(this).val());
            var dateString = d.getDate().toString() + ' ' + $.fn.datepicker.dates[current_locale].monthsShort[d.getMonth()] + ' ' + d.getFullYear().toString();
            $(this).val(dateString);
        }
    });

    $("#date-valid").datepicker({
        format: 'd M yyyy',
        endDate: '+72m',
        startDate: '-48m',
        autoclose: true,
        todayBtn: true,
        todayHighlight: true,
        language: current_locale,
    }).on('hide', function (e) {
        var dateString = e.date.getFullYear().toString() + '-' + two_days_digit(e.date.getMonth() + 1) + '-' + two_days_digit(e.date.getDate());
        $('#real-date-valid').attr('value', dateString);
    }).each(function() {
        if ($(this).val() != '0000-00-00') {
            var d = new Date($(this).val());
            var dateString = d.getDate().toString() + ' ' + $.fn.datepicker.dates[current_locale].monthsShort[d.getMonth()] + ' ' + d.getFullYear().toString();
            $(this).val(dateString);
        }
    });

    $("#date-valid-past").datepicker({
        format: 'd M yyyy',
        endDate: '+0d',
        startDate: '-48m',
        autoclose: true,
        todayBtn: true,
        todayHighlight: true,
        language: current_locale,
    }).on('hide', function (e) {
        console.log(e);
        var dateString = e.date.getFullYear().toString() + '-' + two_days_digit(e.date.getMonth() + 1) + '-' + two_days_digit(e.date.getDate());
        $('#real-date-valid-past').attr('value', dateString);
    }).each(function() {
        if ($(this).val() != '0000-00-00') {
            var d = new Date($(this).val());
            var dateString = d.getDate().toString() + ' ' + $.fn.datepicker.dates[current_locale].monthsShort[d.getMonth()] + ' ' + d.getFullYear().toString();
            $(this).val(dateString);
        }
    });


    //start-end
    $("#end-field").datepicker({
        format: 'd M yyyy',
        autoclose: true,
        todayBtn: true,
        todayHighlight: true,
        language: current_locale,
    }).on('hide', function (e) {
        var dateString = e.date.getFullYear().toString() + '-' + two_days_digit(e.date.getMonth() + 1) + '-' + two_days_digit(e.date.getDate());
        $('#real-end-date').attr('value', dateString);
    }).each(function() {
        if ($(this).val() != '0000-00-00') {
            var d = new Date($(this).val());
            var dateString = d.getDate().toString() + ' ' + $.fn.datepicker.dates[current_locale].monthsShort[d.getMonth()] + ' ' + d.getFullYear().toString();
            $(this).val(dateString);
        }
    });

    $("#start-field").datepicker({
        format: 'd M yyyy',
        autoclose: true,
        todayBtn: true,
        todayHighlight: true,
        language: current_locale,
    }).on('hide', function (e) {
        var dateString = e.date.getFullYear().toString() + '-' + two_days_digit(e.date.getMonth() + 1) + '-' + two_days_digit(e.date.getDate());
        $('#real-start-date').attr('value', dateString);
    }).each(function() {
        if ($(this).val() != '0000-00-00') {
            var d = new Date($(this).val());
            var dateString = d.getDate().toString() + ' ' + $.fn.datepicker.dates[current_locale].monthsShort[d.getMonth()] + ' ' + d.getFullYear().toString();
            $(this).val(dateString);
        }
    });

    /*.on('changeDate', function(e) {
        var end = $("#end-field");
        var end_date = end.datepicker('getDate');
        alert(end_date+' '+ e.date);
        if(end_date >= e.date) {
            end.datepicker('setStartDate', $(this).val());
        } else if(end_date < e.date) {
            end.datepicker('setDate', $(this).val());
            end.datepicker('setStartDate', $(this).val());
            //update fields
            var dateString = e.date.getFullYear().toString() + '-' + two_days_digit(e.date.getMonth() + 1) + '-' + two_days_digit(e.date.getDate());
            $('#real-end-date').attr('value', dateString);
            $('#end-field').attr('value', dateString);
        }
    })*/

//NOTIFICATION TEXTAREA
    $('#notification_limit textarea').keyup(function() {
        var text_length = $(this).val().length;
        var text_remaining = parseInt($(this).attr('maxlength')) - text_length;

        if(text_remaining < 10 && text_remaining > 0) {
            $('#remaining_char').removeClass('text-danger').addClass('text-warning');
        } else if(text_remaining == 0) {
            $('#remaining_char').removeClass('text-warning').addClass('text-danger');
        } else {
            $('#remaining_char').removeClass('text-warning', 'text-danger');
        }

        $('#remaining_char').html(text_remaining);
    });

//NICE CHECKBOX
    $('.i-checks').iCheck({
        checkboxClass: 'icheckbox_square-green',
        radioClass: 'iradio_square-green',
    });

//CLOCK
    $('.clockpicker').clockpicker({
        autoclose: true
    });

//Fullcalendar
//Fullcalendar
    $('#fullCalendar').fullCalendar({
        header: {
            left: 'prev,next today',
            center: 'title',
            right: 'month,agendaWeek'
        },
        weekNumbers: true,
        //aspectRatio: 2,
        contentHeight: 500,
        fixedWeekCount: false,
        eventSources: [
            {
                url: "events",
                data: function() { // a function that returns an object
                    if($('#calendar_display_all_groups').is(':checked')) {
                        return {
                            show_all_groups: 'true'
                        };
                    } else {
                        return {
                            show_all_groups: 'false'
                        };
                    }
                }
            }
        ],
        eventRender: function (event, element) {
            element.attr('title', event.tooltip);
            if(event.externEv !== undefined) {
                element.addClass('extern');
            }
            if(event.multigroupEv !== undefined) {
                element.addClass('multigroup');
            }
            element.tooltipster({
                contentAsHTML: true
            });
        },
        loading: function (bool) {
            if (!bool) {
                $('#calendar-loading').css("display","none");
            } else {
                $('#calendar-loading').css("display","block");
            }
        },
        eventClick: function(event) {
            if (event.showUrl) {
                window.open(event.showUrl, '_self');
                return false;
            }
        }
    });

//search
//serach
    $("#top-search").keyup(function () {
        var filter = $(this).val();
        var count = 0;

        $(".searchable").each(function () {
            var parent = $(this).parent(), length = $(this).text().length > 0;

            if (length && $(this).text().search(new RegExp(filter, "i")) < 0) {
                parent.hide();
            } else {
                parent.show();
                count++;
            }
        });
        //$("#filter-count").text(count);
        if (filter.length > 0) {
            $('.search-bar .cancel').removeClass('hidden');
        } else {
            $('.search-bar .cancel').addClass('hidden');
        }

        if (count == 0) {
            $(this).addClass('text-danger');
            $('.search-bar .icon').addClass('text-danger');
        } else {
            $(this).removeClass('text-danger');
            $('.search-bar .icon').removeClass('text-danger');
        }
    });

    $('.search-bar .cancel').click(function () {
        $("#top-search").val('');
        $('.search-bar .cancel').addClass('hidden');
        $(".searchable").each(function () {
            var parent = $(this).parent();
            parent.show();
        });
    });

    //DATA CONFIRM
    $('form[data-confirm] [type=submit]').on('click', function(ev) {
        ev.preventDefault();
        var parent = $(this).closest('form[data-confirm]');
        var thisForm = parent;
        if (parent.attr('data-success') !== undefined) {
            var style = 'success';
            var btn_text = parent.attr('data-success');
        } else {
            var style = 'danger';
            var btn_text = app_lng[current_locale].m_delete;
        }
        if (!$('#dataConfirmModal').length) {
            $('body').append('' +
                '<div id="dataConfirmModal" class="modal" role="dialog" aria-labelledby="dataConfirmLabel" aria-hidden="true">' +
                '<div class="modal-dialog"><div class="modal-content">' +
                '<div class="modal-header">' +
                '<button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>' +
                '<h3 id="dataConfirmLabel">' + parent.attr('data-title') + '</h3>' +
                '</div>' +
                '<div class="modal-body"></div>' +
                '<div class="modal-footer">' +
                '<button class="btn" data-dismiss="modal" aria-hidden="true">' + app_lng[current_locale].m_cancel + '</button>' +
                '<a class="btn btn-' + style + '" id="dataConfirmOK">' + btn_text + '</a>' +
                '</div>' +
                '</div></div>' +
                '</div>');
        }
        $('#dataConfirmModal').find('.modal-body').text(parent.attr('data-confirm'));
        $('#dataConfirmOK').bind( "click", function() {
            thisForm.submit();
        });
        $('#dataConfirmModal').modal({show:true});
        return false;
    });

    //SECTOR AND LINE PLACEMENT
    //SECTOR AND LINE PLACEMENT

    //handle one line and place/edit dot
    $('#single_line_image').on('click', function(e) {
        var offset = $(this).offset();
        var holder = $('#gym_image_holder').offset();
        var dot_offset = 26 - 14;

        var pos_x = e.pageX - offset.left;
        var pos_y = e.pageY - offset.top;

        //relative
        pos_x = pos_x / $(this).width();
        pos_y = pos_y / $(this).height();

        $('#line_x').val(pos_x);
        $('#line_y').val(pos_y);

        //position dot
        var dot = $('.line_dot');
        dot.css('visibility', 'visible');
        dot.css('top', (e.pageY - holder.top - dot_offset));
        dot.css('left', (e.pageX - holder.left - dot_offset - 2));
    }).each(function() {
        var img = $(this);
        setTimeout(function(){ //needs timout
            var pos = img.position();
            var dot_offset = 26;
            var padding = 7;
            var pos_dot_x = $('#line_x').val() * img.width();
            var pos_dot_y = $('#line_y').val() * img.height();
            var margin_x = parseInt(img.css('marginLeft').replace('px', ''));
            //position dot
            var dot = $('.line_dot');
            dot.css('visibility', 'visible');
            dot.css('top', pos_dot_y+padding)
            dot.css('left', pos_dot_x+padding)
            //dot.css('top', (pos.top + pos_dot_y - dot_offset));
            //dot.css('left', (pos.left + pos_dot_x + margin_x - dot_offset));
        }, 500);
    });

    //handle displays dots (multiple or one)
    $('div.lines_dots').hover(function() {
        var lineID = $(this).attr('data-id');
        var line = $('#lineID_'+lineID);
        line.addClass('line_hover');
    }, function() {
        var lineID = $(this).attr('data-id');
        var line = $('#lineID_'+lineID);
        line.removeClass('line_hover');
    }).each(function() {
        var obj = $(this);
        var img = $('#lines_image');
        var dot_offset = 26;
        var counter_offset = 16;
        var padding = 7;
        setTimeout(function(){ //needs timeout
            var pos = img.position();
            var pos_dot_x = parseFloat(obj.attr('data-x')) * img.width();
            var pos_dot_y = parseFloat(obj.attr('data-y')) * img.height();
            var counter = parseInt(obj.attr('data-counter'));
            var margin_x = parseInt(img.css('marginLeft').replace('px', ''));
            //position dot
            obj.css('visibility', 'visible');
            obj.css('top', pos_dot_y+padding)
            obj.css('left', pos_dot_x+padding)
            //obj.css('top', (pos.top + pos_dot_y - dot_offset));
            //obj.css('left', (pos.left + pos_dot_x + margin_x - dot_offset - (counter_offset * counter)));
        }, 1200);
    });

    //handle highlight dot on line hover
    $('.line_hoverer').hover(function() {
        var lineID = $(this).attr('data-id');
        var dot = $('#dot_lineID_'+lineID);
        dot.addClass('dot_hover');
    }, function() {
        var lineID = $(this).attr('data-id');
        var dot = $('#dot_lineID_'+lineID);
        dot.removeClass('dot_hover');
    });

    //REMEMBER (settings) JS cookies
    $('#menu_collapse_link').click(function() {
        var cookie = getCookie('climbingymRememberMenu');
        if(cookie == 'open') {
            setCookie('climbingymRememberMenu', '', 30);
        } else {
            setCookie('climbingymRememberMenu', 'open', 30);
        }
    });

    //ROUTE FLAG
    $('button.rb_flag_btn').click(function() {
        var btn = $(this);
        var parent = btn.parent('td');
        var rowParent = parent.parent('tr');
        var groupId = btn.attr('data-group');
        var routeId = btn.attr('data-id');
        var btnStatus = btn.attr('data-status'); //boolean actual state
        //var largeDataTable = $('.dataTables-largeData').DataTable();
        //btn.attr("disabled",true);
        //make ajax call
        $.ajax({
            url: "/en/groups/"+groupId+"/routes/"+routeId+"/flag?group_id="+groupId+"&route_id="+routeId+"&status="+btnStatus, //reverse on server side
            context: document.body,
            error: function(data) {
                console.log('route flag: error occurred ');
                //btn.attr("disabled",false);
            },
            success: function(data) {
                var newSearch = 'none'
                if(btnStatus == '1') {
                    btn.attr('data-status', '0');
                    btn.html('<i class="fa fa-flag-o"></i>'); //inverse
                } else {
                    btn.attr('data-status', '1');
                    newSearch = 'flag';
                    btn.html('<i class="fa fa-flag text-success"></i>'); //inverse
                }
                rowParent.children("td:nth-child(2)").attr('data-search', newSearch);

                largeDataTable.row(rowParent).invalidate();
                //btn.attr("disabled",false);
            }
        });


    });

    $('button.rb_flag2_btn').click(function() {
        var btn = $(this);
        var parent = btn.parent('td');
        var rowParent = parent.parent('tr');
        var groupId = btn.attr('data-group');
        var routeId = btn.attr('data-id');
        var btnStatus = btn.attr('data-status'); //boolean actual state
        //var largeDataTable = $('.dataTables-largeData').DataTable();
        //btn.attr("disabled",true);
        //make ajax call
        $.ajax({
            url: "/en/groups/"+groupId+"/routes/"+routeId+"/flag?group_id="+groupId+"&route_id="+routeId+"&flag2=true&status="+btnStatus, //reverse on server side
            context: document.body,
            error: function(data) {
                console.log('route flag2: error occurred ');
                //btn.attr("disabled",false);
            },
            success: function(data) {
                var newSearch = 'false'
                if(btnStatus == '1') {
                    btn.attr('data-status', '0');
                    btn.html('<i class="fa fa-times"></i>'); //inverse
                } else {
                    btn.attr('data-status', '1');
                    newSearch = 'true';
                    btn.html('<i class="fa fa-check text-success"></i>'); //inverse
                }
                rowParent.children("td:nth-child(3)").attr('data-search', newSearch);

                largeDataTable.row(rowParent).invalidate();
                //btn.attr("disabled",false);
            }
        });


    });
});

//sidebar
//sidebar
$(document).mouseup(function (e)
{
    var sidebar = $('#right-sidebar.sidebar-open');

    if (!sidebar.is(e.target) && sidebar.has(e.target).length === 0) {
        if(sidebar.hasClass('sidebar-open')) {
            sidebar.animate({
                right: "-260"
            }, 200, function() {sidebar.removeClass('sidebar-open');});
        } else {
            sidebar.animate({
                right: "0"
            }, 200, function() {sidebar.addClass('sidebar-open');});
        }
    }
});

//RESIZE
$( window ).resize(function() {
    //LINES DOT
    $('#single_line_image').each(function() {
        var pos = $('#single_line_image').position();
        var dot_offset = 26;
        var pos_dot_x = $('#line_x').val() * $(this).width();
        var pos_dot_y = $('#line_y').val() * $(this).height();
        var margin_x = parseInt($(this).css('marginLeft').replace('px', ''));
        var padding = 7;
        //position dot
        var dot = $('.line_dot');
        dot.css('visibility', 'visible');
        dot.css('top', pos_dot_y+padding)
        dot.css('left', pos_dot_x+padding)  
        //dot.css('top', (pos.top + pos_dot_y - dot_offset));
        //dot.css('left', (pos.left + pos_dot_x + margin_x - dot_offset));
    });

    //handle displays dots (multiple or one)
    $('div.lines_dots').each(function() {
        var obj = $(this);
        var img = $('#lines_image');
        var dot_offset = 26;
        var counter_offset = 16;
        var padding = 7;
        setTimeout(function(){ //needs timeout
            var pos = img.position();
            var pos_dot_x = parseFloat(obj.attr('data-x')) * img.width();
            var pos_dot_y = parseFloat(obj.attr('data-y')) * img.height();
            var counter = parseInt(obj.attr('data-counter'));
            var margin_x = parseInt(img.css('marginLeft').replace('px', ''));
            //position dot
            obj.css('visibility', 'visible');
            obj.css('top', pos_dot_y+padding)
            obj.css('left', pos_dot_x+padding)
            //obj.css('top', (pos.top + pos_dot_y - dot_offset));
            //obj.css('left', (pos.left + pos_dot_x + margin_x - dot_offset - (counter_offset * counter)));
        }, 500);
    });

});


//toaster settings
toastr.options = {
    "closeButton": true,
    "debug": false,
    "progressBar": true,
    "preventDuplicates": false,
    "positionClass": "toast-top-right",
    "onclick": null,
    "showDuration": "400",
    "hideDuration": "1000",
    "timeOut": "7000",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
};
