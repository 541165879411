// SPANISH

app_lng['es'] = { //declared in layout
    // php uploader errors
    e_oops: "¡Ups!",
    e_upload_1: "No se ha cargado ningún archivo.",
    e_upload_2: "El archivo está vacío.",
    e_upload_3: "El archivo debe estar en formato jpeg, jpg o png.",
    e_upload_4: "El archivo debe estar en formato jpeg, jpg, png o pdf.",
    e_upload_5: "¿Estás intentando atacar el servidor? Atente a las consecuencias...",
    e_upload_6: "Error al mover el archivo... Permissions Read/Write/Modify?",
    e_upload_7: "El archivo es demasiado grande (Máx.: 4 MB)",
    e_upload_8: "La imagen debe estar en modo vertical (más alta que ancha) y tener una resolución de 1000px.",

    e_upload_i: "Por favor, elige un formato de imagen válido.",

    e_wrong_format: "<p id='error'>Por favor, elige un formato de imagen válido</p><h4>Note</h4><span id='error_message'>Solo se aceptan los formatos jpeg, jpg y png</span>",

    yes: "Sí",
    no: "No",
    m_cancel: "Cancelar",
    m_delete: "Eliminar",

    placeholder: "Escribe aquí...",
};