// uploader
// uploader
//userId = '0' for all files OR int for user_id
function sendFileToServer(file, userId, successFunc, errorFunc, progressId, progressValId, is_gym_path) {
    is_gym_path = typeof is_gym_path !== 'undefined' ? is_gym_path : '0';
    data = new FormData();
    if(file.size > (1048576 * 6)) { //upper upload limit for whole site: 6 MB
        swal({
            title: app_lng[current_locale].e_oops,
            text: app_lng[current_locale].e_upload_7,
            allowOutsideClick: true,
            type: "error"
        });
        return false;
    }
    $('progress#'+progressId).show();
    $('#'+progressValId).show();
    data.append("upload", file);
    data.append("isUserId", userId);
    data.append("isGymPath", is_gym_path)
    $.ajax({
        data: data,
        type: 'POST',
        xhr: function() {
            var xhr = new window.XMLHttpRequest();
            xhr.upload.addEventListener("progress", function(evt) {
                if (evt.lengthComputable) {
                    var percentComplete = evt.loaded / evt.total;
                    percentComplete = parseInt(percentComplete * 100);

                    $('progress#'+progressId).attr({value:percentComplete, max:100});
                    $('#'+progressValId).html(percentComplete.toString());

                    if (percentComplete === 100) {
                        //$('progress#'+progressId).hide();
                        $('#'+progressValId).html('100');
                        $('#'+progressValId).css('color', 'green');
                    }
                }
            }, false);
            return xhr;
        },
        url: '/script/file_upload.php',
        cache: false,
        contentType: false,
        processData: false,
        success: function(url) {
            successFunc(url);
        },
        error: function(jqXHR, textStatus, errorThrown) {
            errorFunc(errorThrown);
            $('progress#'+progressId).hide();
            $('#'+progressValId).hide();
        }
    });
}

// summernote
// summernote
function sendFile(file, editor) {
    if(file.size > (1048576 * 6)) { //upper upload limit for whole site: 6 MB
        swal({
            title: app_lng[current_locale].e_oops,
            text: app_lng[current_locale].e_upload_7,
            allowOutsideClick: true,
            type: "error"
        });
        return false;
    }
    data = new FormData();
    data.append("upload", file);
    $.ajax({
        data: data,
        type: 'POST',
        xhr: function() {
            var xhr = new window.XMLHttpRequest();
            xhr.upload.addEventListener("progress", function(evt) {
                if (evt.lengthComputable) {
                    var percentComplete = evt.loaded / evt.total;
                    percentComplete = parseInt(percentComplete * 100);

                    $('#summernote_progress').attr({value:percentComplete, max:100});

                    if (percentComplete === 100) {
                        $('#summernote_progress').remove();
                    }
                }
            }, false);
            return xhr;
        },
        url: '/script/summernote_upload.php',
        cache: false,
        contentType: false,
        processData: false,
        success: function(url) {
            editor.summernote('insertImage', url);
            $('#summernote_progress').remove();
        },
        error: function(jqXHR, textStatus, errorThrown) {
            var error_string = 'e_upload_' + errorThrown.toString();
            swal({
                title: app_lng[current_locale].e_oops,
                text: app_lng[current_locale][error_string],
                allowOutsideClick: true,
                type: "error"
            });
        }
    });
}

//DATE pickers
//DATE pickers
function two_days_digit(day){
    if(day.toString().length==1) {
        return '0'+day.toString();
    } else {
        return day.toString();
    }
}

//JS COOKIES
function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + "; " + expires;
}

function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length,c.length);
        }
    }
    return "";
}