// FRENCH

app_lng['fr'] = { //declared in layout
    // php uploader errors
    e_oops: "Oups!",
    e_upload_1: "Aucun fichier chargé.",
    e_upload_2: "Le fichier est vide.",
    e_upload_3: "Le fichier doit être au format jpeg, jpg ou png.",
    e_upload_4: "Le fichier doit être au format jpeg, jpg, png ou pdf.",
    e_upload_5: "Tu veux attaquer ce serveur? Attends-toi à avoir quelqu'un devant ta porte...",
    e_upload_6: "Erreur lors du déplacement du fichier... Permissions Read/Write/Modify?",
    e_upload_7: "Le fichier est trop gros (Max: 4 MB)",
    e_upload_8: "L'image doit être en portrait (plus haute que large) et avoir une largeur de 1000px.",

    e_upload_i: "STP choisis un fichier d'image valide.",

    e_wrong_format: "<p id='error'>STP choisis un fichier d'image valide</p><h4>Note</h4><span id='error_message'>Seulement jpeg, jpg et png est accepté</span>",

    yes: "Oui",
    no: "Non",
    m_cancel: "Annuler",
    m_delete: "Supprimer",

    placeholder: "Tape ici...",
};